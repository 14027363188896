/* eslint-disable i18n/no-chinese-character */
import {useEffect, useMemo, useState} from 'react';
import {useCountDown} from 'ahooks';
import RCDialog from 'rc-dialog';
import toast from 'react-hot-toast';
import {ArrowIcon} from '@/components/common';
import captcha from '@/utils/captcha';
// import captcha from '@/components/captchaNext';
import {codeLogin, bindMobile, getCountry, accountLogin, checkResetPasswordSMS, checkPassword, checkBindMobileSMS} from '@/apis/login';
import i18n from '@/locales/init';
import { Tab } from '../tab';
import { VerifyInput } from '../VerifyInput';


interface Props {
  tosAgreed: boolean;
  // 有微信code，说明需要进行绑定
  wxCode?: string;
  isLong: boolean;
  setIsLong: (isLong: boolean) => void;
  loginSuccess: (token: string, onlySetToken?: boolean) => void;
  setTosAgreed?: (tosAgreed: boolean) => void;
  setTosAgreedVisible?: (visible: boolean) => void;
  notDevInWhiteList?: () => void;
}


export default function NormalLogin({
  tosAgreed,
  wxCode,
  isLong, 
  setIsLong,
  loginSuccess,
  setTosAgreed,
  setTosAgreedVisible,
  notDevInWhiteList,
}: Props) {
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [targetDate, setTargetDate] = useState<number>(0);
  const [smsTypeList, setSmsTypeList] = useState<Array<{
    id: number;
    name: string;
    code: string;
    emoji: string;
  }>>([]);
  const [smsType, setSmsType] = useState<number>(1);
  const [showWrongPhone, setShowWrongPhone] = useState(false);
  const [loginType, setLoginType] = useState<number>(0); // 1账号登录 其他手机登录
  const [pageStatus, setPageStatus] = useState<number>(0);
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const shouldTosAgree = loginType !== 3 && loginType !== 4 && loginType !== 2;

  const [showUsernameInfoDialog, setShowUsernameInfoDialog] = useState<boolean>(false);

  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
      localStorage.removeItem('verifyCount');
    },
  });

  useEffect(()=>{
    let visible = true;
    if (loginType === 3 || (loginType === 0 && pageStatus === 1) || loginType === 4 || loginType === 2){
      visible = false;
    }
    setTosAgreedVisible && setTosAgreedVisible(visible);
    setPassword('');
    setPassword1('');
    setPassword2('');
  },[loginType, pageStatus]);
  
  useEffect(()=>{
    setPhone('');
    setPhoneCode('');
    setAccount('');
  }, [loginType]);


  // 验证手机号
  function validateMobile() {
    if (!/^\d+$/.test(phone)) {
      return false;
    }
    
    const reg = {
      '1': /^1[3-9]\d{9}$/,
      '2': /^(8|9)\d{7}$/,
      '3': /^0[689]\d{8}$/,
    }[smsType];
    if (reg && !reg.test(phone)){
      return false;
    }
    return true;
  }

  const validateMobileL = ()=>{
    const verifyInput = validateMobile();
    const length = {'1': 11, '2': 8, '3': 10}[smsType] || 11;
    return !verifyInput && phone.length === length;
  };
  
  useEffect(()=>{
    setShowWrongPhone(validateMobileL());
  },[phone, smsType]);

  useEffect(() => {
    getCountry().then(({data}) => {
      setSmsTypeList(data.list);
      setSmsType(data.list[0].id);
    });
    const verifyData = localStorage.getItem('verifyCount');
    if (verifyData){
      setTargetDate(Number(verifyData));
    } 

    // setLoginType(4);
    // setPageStatus(0);
  }, []);

  useEffect(()=>{
    if (wxCode){
      setLoginType(2);
      setPageStatus(0);
    }
  },[wxCode]);

  /**
   * 手机验证登录, 获取验证码
   * @returns 
   */
  const handleVerify = (caseCountdown = true)=>{
    if (caseCountdown && countdown > 0){
      toast.error(i18n.t('common.dauyan_account_retry_later'));
      return;
    }
    if (phone.length === 0 ){
      toast.error(i18n.t('common.dauyan_account_phone_placeholder'));
      return;
    }
    if (!validateMobile()) {
      setShowWrongPhone(true);
      return;
    }

    if (shouldTosAgree && !tosAgreed) {
      toast.error(i18n.t('common.dauyan_account_agreement_notice'));
      return;
    }
    setPhoneCode('');
    const smsType = loginType === 3 ? 1 : loginType === 4 ? 2 : 0;
    if (currentSMSType){
      captcha(phone, currentSMSType?.id, smsType).then(success => {
        if (success) {
          const data = Date.now() + 60 * 1000;
          if (caseCountdown){
            localStorage.setItem('verifyCount', String(data));
          }
          setTargetDate(data);
          setPageStatus(1);
        }
      }).catch(e => {
        console.log('Error', e);
        if (e.code === 3006 ||(e.response && e.response.data.code === 3006)) {
          notDevInWhiteList && notDevInWhiteList();
        } else {
          toast.error(e?.msg || e?.response?.data?.msg || i18n.t('common.dauyan_account_send_failed'));
        }
      });
    }
  };

  /**
   * 点击登录|注册 / 绑定手机号
   * @returns 
   */
  const handleSubmit = ()=>{
    if (shouldTosAgree && !tosAgreed) {
      toast.error(i18n.t('common.dauyan_account_agreement_notice'));
      return;
    }

    if (!validateMobile()) {
      return;
    }
    if (!phoneCode || phoneCode.length !== 6 || !/^\d+$/.test(phoneCode)) {
      toast.error(i18n.t('common.dauyan_account_check_verification'));
      return;
    }

    try {
      if (wxCode) {
        bindMobile(phone, currentSMSType?.id || 1, phoneCode, wxCode, isLong).then(({data}) => {
          loginSuccess(data.token);
        }).catch(e => {
          toast.error(e?.response?.data?.msg ?? e?.message ?? i18n.t('common.dauyan_account_link_account_failed'));
        });
        return;
      }
      if (loginType === 4){
        checkBindMobileSMS(phone, currentSMSType?.id || 1, phoneCode).then(res=>{
          loginSuccess('');
        }).catch(err=>{
          toast.error(err.msg);
        });
        return;
      }
      codeLogin(phone, currentSMSType?.id || 1, phoneCode, isLong).then(({data}) => {
        loginSuccess(data.token);
      }).catch(e => {
        console.log('[Error]', e);
        if (e.code === 3006 || e.response.data.code === 3006) {
          notDevInWhiteList && notDevInWhiteList();
        }
        toast.error(e?.response?.data?.msg ?? e?.message ?? i18n.t('common.dauyan_account_link_account_failed'));
      });

    } catch (e) {
      toast.error(i18n.t('common.dauyan_account_verify_error'));
      return;
    }

  };
  /**
   * 账号密码登录
   * @returns 
   */
  const handleLogin = ()=>{
    if (!tosAgreed){
      toast.error(i18n.t('common.dauyan_account_agreement_notice'));
      return;
    }
    if (!account) {
      toast.error(i18n.t('common.dauyan_account_input_account'));
      return;
    }
    if (!password) {
      toast.error(i18n.t('common.dauyan_account_input_password'));
      return;
    }

    accountLogin(account, password, isLong).then((res)=>{
      if (res.data.is_bind_mobile === 2){
        loginSuccess(res.data.token, true);
        setLoginType(4);
      } else {
        loginSuccess(res.data.token);
      }
    }).catch((err)=>{
      toast.error(err.msg);
    });
  };
  
  /**
   * 检查验证码
   * @returns 
   */
  const handleCheckVerify = ()=>{
    if (!phoneCode || phoneCode.length !== 6 || !/^\d+$/.test(phoneCode)) {
      toast.error(i18n.t('common.dauyan_account_check_verification'));
      return;
    }
    checkResetPasswordSMS(phone, currentSMSType?.id || 1, phoneCode).then(res=>{
      if (res.data.result){
        setPageStatus(2);
      }
    }).catch(err=>{
      toast.error(err.msg);
    });
  };

  /**
   * 重置密码
   * @returns 
   */
  const handleResetPassword = ()=>{
    if (password1 && password2){
      if (password1.length < 6){
        toast.error(i18n.t('common.dauyan_account_password_too_short'));
        return;
      }
      if (password1 !== password2){
        toast.error(i18n.t('common.dauyan_account_password_not_same'));
        return;
      }
      checkPassword(phone, currentSMSType?.id || 1, password1, password2).then(res=>{
        if (res.code === 200){
          setPageStatus(3);
        } else {
          toast.error(res.msg);
        }
      }).catch(err=>{
        toast.error(err.msg || err?.response?.data?.msg);
      });
    } else {
      if (!password1){
        toast.error(i18n.t('common.dauyan_account_input_password'));
      } else if (!password2){
        toast.error(i18n.t('common.dauyan_account_input_password_t'));
      }
    }
  };

  const handleBack = (step:number)=>{
    setPageStatus(step);
  };

  const handleForgetPassword = ()=>{
    setLoginType(3);
    // setPageStatus(3);
  };

  const handleCannotLogin = () => {
    setShowUsernameInfoDialog(true);
  };


  const currentSMSType = useMemo(() => {
    return smsTypeList.find(item => item.id === smsType);
  },[smsTypeList, smsType]);

  
  /**
   * 账号输入
   */
  const accountInput = useMemo(()=> <div className="code-wrap">
    <input
      className="code input-box"
      value={account}
      onChange={e => {
        const s = e.target.value.replace(/\s/,'');
        setAccount(s);
      }}
      placeholder={i18n.t('common.dauyan_account_input_account')}/>
  </div>,[account]);

  /**
   * 密码输入
   */
  const passwordInput = useMemo(()=><div className="code-wrap" style={{ paddingBottom: '10px' }}>
    <input
      autoComplete='off'
      className="code input-box password-with-info"
      type='password'
      value={password}
      onChange={e => {
        const s = e.target.value.replace(/\s/,'');
        setPassword(s);
      }}
      placeholder={i18n.t('common.dauyan_account_input_password')}/>
    <div className='cannot-login' onClick={handleCannotLogin}>{i18n.t('common.dauyan_account_cannot_login')}</div>
    <div className='forget-password' onClick={handleForgetPassword}>{i18n.t('common.dauyan_account_forget_password')}</div>
  </div>,[password]);

  const userNameInfoDialog = useMemo(() => {
    return <RCDialog
      title={<span style={{ fontSize: '18px', color: '#000000E5' }}>{i18n.t('common.dauyan_account_cannot_login')}</span>}
      mask
      maskClosable
      visible={showUsernameInfoDialog}
      onClose={() => { setShowUsernameInfoDialog(false); }}
      style={{ width: '600px', padding: '20px', marginTop: '150px' }}
      getContainer={() => document.body}
    >
      <div style={{ fontSize: '14px', position: 'relative', marginTop: '0', color: '#00000099' }}>
        {/* <p style={{ marginBottom: '15px' }}><span>{i18n.t('使用【学号】登录的学生，登录账号为：')}</span><span style={{ fontWeight: '500' }}>{i18n.t('【学校代号-学生学号】')}</span><span>{i18n.t(', 例如：')}</span></p>
        <p style={{ paddingLeft: '0px' }}><span>{i18n.t('北外的学校代号：')}</span><span style={{ fontWeight: '500' }}>{i18n.t('bfsu')}</span></p>
        <p style={{ paddingLeft: '0px' }}><span>{i18n.t('学生学号：')}</span><span style={{ fontWeight: '500' }}>{i18n.t('2345678')}</span></p>
        <p style={{ paddingLeft: '0px' }}><span>{i18n.t('登录账号：')}</span><span style={{ fontWeight: '500' }}>{i18n.t('bfsu-2345678')}</span></p>
        <p style={{ marginTop: '30px', fontSize: '14px', fontWeight: '500' }}>{i18n.t('仍无法登录请联系客服：4008089750')}</p>
        <div className="btn" style={{ position: 'absolute', bottom: -10, right: 0, paddingLeft: '10px', paddingRight: '10px', marginBottom: 0 }} onClick={() => { setShowUsernameInfoDialog(false); }}>{i18n.t('我知道了')}</div> */}
        <p>{i18n.t('学生账号应为学校小写简称-学号，例：')}</p>
        <p>{i18n.t('bfsu-2024536718')}</p>
        <p style={{ marginBottom: '30px' }}>{i18n.t('仍无法登陆请联系客服：4008089750')}</p>
        <div className="btn" style={{ position: 'absolute', bottom: '-40px', right: 0, paddingLeft: '20px', paddingRight: '20px', height: '36px', lineHeight: '36px', marginBottom: 0, textAlign: 'center' }} onClick={() => { setShowUsernameInfoDialog(false); }}>{i18n.t('我知道了')}</div>
      </div>
    </RCDialog>;
  }, [showUsernameInfoDialog]);

  const passwordInput1 = useMemo(()=><div className="code-wrap">
    <input
      value={password1}
      className="code input-box"
      type='password'
      onChange={e => {
        const s = e.target.value.replace(/[^A-Za-z0-9]/g, '').substring(0, 16);
        setPassword1(s);
      }}
      placeholder={i18n.t('common.dauyan_account_input_password')}/>
  </div>,[password1]);
  const passwordInput2 = useMemo(()=><div className="code-wrap password2">
    <input
      value={password2}
      className="code input-box"
      type='password'
      onChange={e => {
        const s = e.target.value.replace(/[^A-Za-z0-9]/g, '').substring(0, 16);
        setPassword2(s);
      }}
      placeholder={i18n.t('common.dauyan_account_input_password_t')}/>
  </div>,[password2]);

  /**
   * 手机号输入
   */
  const mobileInput = useMemo(()=><div className="mobile-wrap">
    <div className="area-code-container flex justify-center items-center space-x-2 select-none group">
      <span className="text-2xl text-gray-600 font-bold ">
        <span>+{currentSMSType?.code}</span>
      </span>
      <span className="w-3"><ArrowIcon/></span>
      <div className="absolute hidden group-hover:block top-16 -right-12">
        <ul className="font-normal bg-white p-2 w-48 border rounded-xl">
          {
            smsTypeList.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setSmsType(item.id);
                  }}
                  className="flex justify-between w-full hover:bg-gray-100 py-2 px-3 rounded">
                  <span className="text-lg text-gray-400">{item.name}</span>
                  <span className="text-lg text-gray-600 font-bold">{item.code}</span>
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
    <div className="input-wrap">
      <input
        className="mobile input-box" placeholder={i18n.t('common.dauyan_account_phone_placeholder')} value={phone}
        onChange={e => {
          setPhone(String(e.target.value).replace(/[^\d]/g,'').substring(0, 11));
        }}/>
    </div>
    {
      showWrongPhone && <div className='wrong-phone'>{i18n.t('common.dauyan_account_phone_no_error')}</div>
    }
  </div>,[currentSMSType, phone, showWrongPhone]);

  /**
   * 验证码输入框
   */
  const verifyInput2 = useMemo(()=><VerifyInput value={phoneCode} className='verify-input2' onChange={(v)=>{setPhoneCode(v);}}/>,[phoneCode]);

  /**
   * 手机登录｜账号密码tab
   */
  const loginTab = useMemo(()=> <Tab className='title-tab' 
    values={[i18n.t('common.dauyan_account_login_phone_no'), 
      i18n.t('common.dauyan_account_login_account')]} 
    index={loginType} onChange={(value)=>{
      setShowUsernameInfoDialog(false);
      setLoginType(value);
      setPageStatus(0);
    }}></Tab>,[loginType]);
  const bindTitle = useMemo(()=> <div className='title'>{i18n.t('common.dauyan_account_bind_phone_no')}</div>,[]);
  const loginTitle = useMemo(()=> <div className='title'>{i18n.t('common.dauyan_account_login_title')}</div>,[]);
  const inputVerifyTitle = useMemo(()=> <div className='title'>{i18n.t('common.dauyan_profile_input_verification_code')}</div>,[]);
  const resetPasswordTitle = useMemo(()=> <div className='title'>{i18n.t('common.dauyan_account_reset_password')}</div>,[]);
  const newPasswordTitle = useMemo(()=> <div className='title'>{i18n.t('common.dauyan_account_set_new_password')}</div>,[]);

  const signupTip = useMemo(()=> <div className='signup-tip' >{i18n.t('common.dauyan_account_will_signup')}</div>,[]);
  const inputVerifyTip = useMemo(()=> <div className='signup-tip' >{i18n.t('common.dauyan_account_input_verify_tip', {value: `+${currentSMSType?.code} ` + getMaskPhone(phone)})}</div>,[phone, currentSMSType]);
  const reVerifyTip = useMemo(()=> {
    const d = Math.round(countdown / 1000);
    return <div onClick={()=>{handleVerify();}} className='signup-tip reVerifyTip' data-active={d <= 0}>{i18n.t('common.dauyan_account_re_get_verify')}{countdown > 0 ? ` ${d}s` : ''}</div>;
  },[countdown]);
  const bindedPhoneTip = useMemo(()=> <div className='signup-tip' >{i18n.t('common.dauyan_account_bindedPhoneTip')}</div>,[]);
  const passwordLimitTip = useMemo(()=> <div className='signup-tip' >{i18n.t('common.dauyan_account_password_limit')}</div>,[]);

  /**
   * 记住登录状态
   */
  const longCheck = useMemo(()=><div className="status-container">
    <input
      checked={isLong}
      onChange={e => setIsLong(e.target.checked)}
      type="checkbox"
      className="checkspan"
      title={i18n.t('common.dauyan_account_remember_account')}/>
    <span className="checkdesc">{i18n.t('common.dauyan_account_remember_status')}</span>
  </div>,[isLong]);

  /**
   * 手机验证登录
   */
  const loginVerifyBtn = (()=> { 
    const d = Math.round(countdown / 1000);
    const t = d > 0 ? `(${d}s)` : '';
    return <div className="btn" onClick={()=>{handleVerify();}}>
      {i18n.t('common.dauyan_profile_get_verification_code')}{t}
    </div>;})();
  /**
   * 绑定手机号
   */
  const bindVerifyBtn = (()=> { 
    // const d = Math.round(countdown / 1000);
    // const t = d > 0 ? `(${d}s)` : '';
    const t = '';
    return <div className="btn" onClick={()=>{
      handleVerify(false);
    }}>
      {i18n.t('common.dauyan_profile_get_verification_code')}{t}
    </div>;})();
  /**
   * 注册或登录按钮
   */
  const loginOrSignupBtn = (()=> { return <div className="btn" onClick={handleSubmit}>
    {i18n.t('common.dauyan_account_login_or_signup')}
  </div>;})();
  /**
   * 登录按钮
   */
  const loginBtn = (()=> { return <div className="btn" onClick={handleLogin}>
    {i18n.t('common.dauyan_account_login')}
  </div>;})();
  /**
   * 绑定按钮
   */
  const bindBtn = (()=> { return <div className="btn" onClick={handleSubmit}>
    {i18n.t('common.dauyan_submit')}
  </div>;})();

  /**
   * 重置密码按钮
   */
  const resetBtn = (()=> { return <div className="btn" onClick={handleCheckVerify}>
    {i18n.t('common.dauyan_account_reset_password')}
  </div>;})();
  /**
   * 确定重置密码
   */
  const confirmBtn = (()=> { return <div className="btn" onClick={handleResetPassword}>
    {i18n.t('common.dauyan_account_confirm')}
  </div>;})();

  /**
   * 回退按钮
   * @param step 
   * @returns 
   */
  const backBtn = (step?:number, fn?:()=>void)=> { 
    return <div className="back-btn" 
      onClick={()=>{
        if (step!==undefined){
          handleBack(step);
        }
        if (fn) fn();
      }}>{'<'}</div>;
  };

  return (
    <>
      {
        (loginType === 0 || loginType === 1) && pageStatus === 0 && <>
          {loginTitle}
          {loginTab}
        </>
      }
      {
        // 手机登录
        loginType === 0 && <> 
          {
            // 输入手机号，发送验证码
            pageStatus === 0 && <>
              
              {signupTip}
              {mobileInput}
              {longCheck}
              {loginVerifyBtn}
            </>
          }
          {
            // 输入验证码
            pageStatus === 1 && <>
              {backBtn(0)}
              {inputVerifyTitle}
              {inputVerifyTip}
              {verifyInput2}
              {reVerifyTip}
              {loginOrSignupBtn}
            </>
          }
        </>
      }
      {
        // 账号密码登录
        loginType === 1 && <>
          {
            // 输入账号密码
            pageStatus === 0 && <>
              {accountInput}
              {passwordInput}
              {longCheck}
              {loginBtn}
              {userNameInfoDialog}
            </>
          }
        </>
      }
      {
        // 微信登录后的绑定手机号
        loginType === 2 && <>
          {
            // 输入手机号
            pageStatus === 0 && <>
              {bindTitle}
              {mobileInput}
              {bindVerifyBtn}
            </>
          }
          {
            // 验证
            pageStatus === 1 && <>
              {bindTitle}
              {inputVerifyTip}
              {verifyInput2}
              {reVerifyTip}
              {bindBtn}
            </>
          }
        </>
      }
      {
        // 重置密码
        loginType === 3 && <>
          {
            // 输入手机号，发送验证码
            pageStatus === 0 && <>
              {backBtn(undefined,()=>{
                setLoginType(1);
              })}
              {resetPasswordTitle}
              {bindedPhoneTip}
              {mobileInput}
              {bindVerifyBtn}
            </>
          }
          {
            // 输入验证码
            pageStatus === 1 && <>
              {backBtn(0)}
              {inputVerifyTitle}
              {inputVerifyTip}
              {verifyInput2}
              {reVerifyTip}
              {resetBtn}
            </>
          }
          {
            // 输入新密码
            pageStatus === 2 && <>
              {newPasswordTitle}
              {passwordInput1}
              {passwordInput2}
              {passwordLimitTip}
              {confirmBtn}
            </>
          }
          {
            // 重置成功
            pageStatus === 3 && <>
              <ResetSuccess onEnd={()=>{
                setLoginType(1);
                setPageStatus(0);
              }}></ResetSuccess>
            </>
          }
        </>
        
      }
      {
        // 账号密码登录后，需要绑定手机号
        loginType === 4 && <>
          {
            pageStatus === 0 && <>
              {bindTitle}
              {mobileInput}
              {bindVerifyBtn}
            </>
          }
          {
            pageStatus === 1 && <>
              {bindTitle}
              {inputVerifyTip}
              {verifyInput2}
              {reVerifyTip}
              {bindBtn}
            </>
          }
        </>
      }
    </>
  );
}

const ResetSuccess = (props:{onEnd:()=>void;})=>{
  const [targetDate, setTargetDate] = useState<number>(0);
  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
      props.onEnd();
    },
  });
  useEffect(()=>{
    setTargetDate(Date.now() + 3 * 1000);
  },[]);

  return (
    <>
      <div className='title'>{i18n.t('common.dauyan_account_reset_password_success')}</div>
      <div className='success-content'>
        <div className='success-icon'>
          <svg width="43" height="36" viewBox="0 0 43 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.825195 18.8629C0.825195 18.8629 11.6893 27.7497 12.7685 35.3078C12.7685 35.3078 27.0141 11.9879 42.8426 9.90137C42.8426 9.90137 38.0221 6.38837 39.605 0.835938C39.605 0.835938 30.8274 1.7118 14.2794 27.3971L6.52384 14.2322L0.825195 18.8629Z" fill="#1C9B79"/>
          </svg>
        </div>
        <div className='signup-tip reset-suc-tip'>{i18n.t('common.dauyan_account_reset_password_success_tip')}{Math.round(countdown / 1000)}{'s...'}</div>
      </div>
    </>
      
  );
};

const getMaskPhone = (str: string)=>{
  if (str.length < 4) {
    return str; // 如果字符串长度不足4个字符，则不进行任何替换
  }
    
  const start = str.slice(0, 3); // 获取前三个字符
  const replacement = '****'; // 五个星号替换第4到第8个字符
    
  if (str.length <= 7) {
    return start + replacement.slice(0, str.length - 3); // 如果字符串长度在4到8之间，适当调整星号的数量
  } else {
    const end = str.slice(7); // 获取第8个字符之后的所有字符
    return start + replacement + end;
  }
};